import React, { useState } from 'react';

export const CodeContext = React.createContext<any>(null);

const CodeProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [view, setView] = useState('');

	// Render
	return <CodeContext.Provider value={{ open, setOpen, view, setView }}>{children}</CodeContext.Provider>;
};

const useCode = () => {
	return React.useContext(CodeContext);
};

export { CodeProvider, useCode };
