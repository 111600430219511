import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AppProps } from 'next/app';
import { Inter, Plus_Jakarta_Sans } from 'next/font/google';

import 'icons/fa-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'styles/_base.css';
import { InterfaceProvider } from '@playbooks/interface/context';
import { AccountProvider } from 'contexts/account-context';
import { AppProvider } from 'contexts/app-context';
import { IntercomProvider } from 'contexts/intercom-context';
import { LoadingProvider } from 'contexts/loading-context';
import { MixpanelProvider } from 'contexts/mixpanel-context';
import { PageContext, PageProvider } from 'contexts/page-context';
import { SearchProvider } from 'contexts/search-context';
import { SessionProvider } from 'contexts/session-context';
import { StorageProvider } from 'contexts/storage-context';
import { StoreProvider } from 'contexts/store-context';
import { StripeProvider } from 'contexts/stripe-context';
import { ThemeProvider } from 'contexts/theme-context';
import { ToastProvider } from 'contexts/toast-context';
import { env, isEmpty } from 'utils';
import * as logger from 'utils/logger';

const OG_DOMAIN = process.env.NEXT_PUBLIC_OG_DOMAIN;

const inter = Inter({
	weight: ['100', '300', '400', '500', '700'],
	style: ['normal'],
	subsets: ['latin'],
	variable: '--font-inter',
	display: 'swap',
});
const plusJakartaSans = Plus_Jakarta_Sans({
	weight: ['200', '300', '400', '500', '700', '800'],
	style: ['normal'],
	subsets: ['latin'],
	variable: '--font-plus-jakarta',
	display: 'swap',
});

const App = ({ Component, pageProps }: AppProps) => {
	// Computed
	const meta = {
		type: 'website',
		locale: 'en_US',
		favicon: `/favicon.png`,
		photo: `${OG_DOMAIN}/api/image?template=app&url=www.playbooks.xyz&format=png`,
		title: 'Playbooks',
		description: `Playbooks is a digital marketplace where developers can buy, sell, and exchange code seamlessly. Jumpstart your next project with a premium software template.`,
		keywords: `hybrid source software, developer economy, indie developers, software templates, software recipes, software starters, software themes, software apps`,
		card: 'summary',
		siteName: 'Playbooks',
		author: '@playbooksxyz',
		baseUrl: process.env.NEXT_PUBLIC_WEB_DOMAIN,
		robots: env === 'production',
	};

	// Hooks
	useEffect(() => {
		document.body.classList?.add(inter.variable);
		document.body.classList?.add(plusJakartaSans.variable);
	}, []);

	useEffect(() => {
		const formattedProps = {};
		Object.keys(pageProps)
			.filter(key => !['_sentryTraceData', '_sentryBaggage'].includes(key))
			.map(key => (formattedProps[key] = pageProps[key]));
		// logger.debug('env: ', process.env.NEXT_PUBLIC_NODE_ENV);
		if (!isEmpty(formattedProps)) logger.debug('ssrContext: ', formattedProps);
	}, [pageProps]);

	// Render
	return (
		<ToastProvider>
			<LoadingProvider>
				<StorageProvider>
					<MixpanelProvider>
						<ThemeProvider>
							<StoreProvider>
								<SessionProvider>
									<InterfaceProvider meta={meta}>
										<StripeProvider>
											<IntercomProvider>
												<SearchProvider>
													<AppProvider>
														<AccountProvider>
															<PageProvider ssr={pageProps}>
																<PageContext.Consumer>
																	{contexts => (
																		<DndProvider backend={HTML5Backend}>
																			<Component ssr={pageProps} {...contexts} />
																		</DndProvider>
																	)}
																</PageContext.Consumer>
															</PageProvider>
														</AccountProvider>
													</AppProvider>
												</SearchProvider>
											</IntercomProvider>
										</StripeProvider>
									</InterfaceProvider>
								</SessionProvider>
							</StoreProvider>
						</ThemeProvider>
					</MixpanelProvider>
				</StorageProvider>
			</LoadingProvider>
		</ToastProvider>
	);
};

export default App;
