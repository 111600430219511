import { useEffect } from 'react';

export const useResize = (method, listeners) => {
	useEffect(() => {
		method();
		window.addEventListener('resize', method);
		return () => window.removeEventListener('resize', method);
	}, [...listeners]);
};

export const useResizeTarget = (target, method, listeners) => {
	useEffect(() => {
		method();
		const element = document.getElementById(target);
		element.addEventListener('resize', method);
		return () => element.removeEventListener('resize', method);
	}, [...listeners]);
};

// Docs
// https://www.geeksforgeeks.org/react-onresize-event/
