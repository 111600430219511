export { faBitbucket as fabBitbucket } from '@fortawesome/free-brands-svg-icons';
export { faBluesky as fabBluesky } from '@fortawesome/free-brands-svg-icons';
export { faCss3 as fabCss3 } from '@fortawesome/free-brands-svg-icons';
export { faDiscord as fabDiscord } from '@fortawesome/free-brands-svg-icons';
export { faFacebookF as fabFacebookF } from '@fortawesome/free-brands-svg-icons';
export { faGit as fabGit } from '@fortawesome/free-brands-svg-icons';
export { faGitlab as fabGitlab } from '@fortawesome/free-brands-svg-icons';
export { faGithub as fabGithub } from '@fortawesome/free-brands-svg-icons';
export { faGolang as fabGolang } from '@fortawesome/free-brands-svg-icons';
export { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons';
export { faHtml5 as fabHtml5 } from '@fortawesome/free-brands-svg-icons';
export { faJs as fabJs } from '@fortawesome/free-brands-svg-icons';
export { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons';
export { faPython as fabPython } from '@fortawesome/free-brands-svg-icons';
export { faPhp as fabPhp } from '@fortawesome/free-brands-svg-icons';
export { faRust as fabRust } from '@fortawesome/free-brands-svg-icons';
export { faSlack as fabSlack } from '@fortawesome/free-brands-svg-icons';
export { faStripeS as fabStripeS } from '@fortawesome/free-brands-svg-icons';
export { faSwift as fabSwift } from '@fortawesome/free-brands-svg-icons';
export { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons';
export { faXTwitter as fabXTwitter } from '@fortawesome/free-brands-svg-icons';
export { faYoutube as fabYoutube } from '@fortawesome/free-brands-svg-icons';

// Docs
// https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
