;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"bb1c72ca5a0fc51aa1992b2a60bde9d655824b97"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_NODE_ENV,
	tracesSampleRate: 1.0,
	enabled: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
});
