import React, { useEffect, useState } from 'react';

import { useSession } from 'contexts/session-context';
import { useStorage } from 'contexts/storage-context';
import { useStore } from 'contexts/store-context';
import { useToast } from 'contexts/toast-context';
import { useQuery, useResize } from 'hooks';

export const AppContext = React.createContext(null);

const AppProvider = ({ children }) => {
	const [taxonomy, setTaxonomy] = useState<any>({});
	const [mobile, setMobile] = useState(false);
	const session = useSession();
	const storage = useStorage();
	const store = useStore();
	const toast = useToast();
	const contexts = { session, storage, store, toast, children };

	// Hooks
	useEffect(() => {
		fetchData();
	}, []);

	useResize(() => {
		setMobile(window.innerWidth <= 640 ? true : false);
	}, []);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		const response = await store.queryRecord({
			url: `/taxonomy`,
			headers: { Authorization: '' },
			params: { 'meta.totalRepos': 1, pageSize: 5, sortProp: 'name', sortValue: 'asc' },
		});
		setTaxonomy(response.data);
	});

	// Render
	return <AppContext.Provider value={{ ...contexts, mobile, taxonomy, loading }}>{children}</AppContext.Provider>;
};

const useApp = () => {
	return React.useContext(AppContext);
};

export { AppProvider, useApp };
